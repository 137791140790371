<template>
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <img src="assets/layout/images/emersion-logo-blue-login.png" alt="Nimbus Logo"/>

                <div v-if="showAccountLocked" class="error-wrapper error-wrapper-height">
                    <div class="svg-icon">
                        <img src="assets/layout/images/icons/locked-account.svg" title="Account Locked"/>
                    </div>
                    <div class="error-text">
                        <label>
                            {{ t('account_locked', 'You entered an incorrect username or password multiple times and your account is temporarily locked.')
                            }} <span>{{ t('contact_administrator', 'Please contact your administrator.')
                            }}</span></label
                        >
                    </div>
                </div>

                <div v-if="isIdleWarningShowed" class="error-wrapper error-wrapper-height">
                    <div class="svg-icon">
                        <img src="assets/layout/images/icons/logout-notif.svg" title="Account Logout"/>
                    </div>
                    <div class="error-text block">
                        <label
                            class="font-bold text-xl mb-3 block"
                            v-html="t('lbl_idle_warning', 'You have been logged out.')"></label>
                        <label>
                            <span
                                v-html="t('msg_idle_warning', 'Your account has been idle for a while and for security reasons, you have been automatically logged out.')"></span>
                        </label>
                    </div>
                </div>

                <form @submit.prevent="authenticate" v-if='!loggedIn' class="w-full">
                    <div class="login-form block">
                        <h2>{{ t('account_login', 'ACCOUNT LOGIN') }}</h2>
                        <div class="login-form__form m-auto flex flex-column align-items-center">
                            <InputText
                                v-model="form.username"
                                type="email"
                                :placeholder="t('lbl_username', 'Username')"
                                required
                            />
                            <InputText
                                v-model="form.password"
                                type="password"
                                :placeholder="t('lbl_password', 'Password')"
                                required
                            />
                            <Button
                                :label="t('btn_continue', 'CONTINUE')"
                                class="w-full nb-w-4/5"
                                type="submit"
                            />
                            <a class="mt-3" @click="forgotPassword">{{ t('lbl_forgot_password', 'Forgot Password?') }}</a>
                        </div>
                        <Toast/>

                        <p v-if="this.$route.query.error && this.$route.query.error === '401'" class="mt-4"
                           style="color: red">Authentication Error. You are required to login.</p>
                    </div>
                </form>
                <div v-else style='text-align:left;' class='align-items-start grid'>
                    <div class='col-12'>
                        <TwoFA
                            v-if="userRealm.default_security == authenticationType2FA"
                            @twoFAStatus='twoFAStatus'
                            :is_from_login='true'
                            :user_realm='userRealm'
                            :heading="t('two_fa_title', 'Multifactor Authentication')"></TwoFA>
                    </div>
                </div>
                <p></p>
            </div>
            
            <login-image></login-image>
        </div>
    </div>
    <Dialog :header="t('prelogin_text', 'You are about to login')" v-model:visible="loggingIn"
            :closable="false"
            :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
        <p>
            {{ t('lbl_post_login', 'Logging in please wait...') }}
        </p>
    </Dialog>

    <Dialog v-model:visible="showConfirmForce2fa"
            :header="t('two_fa_force_2fa_mandatory','As an added layer of security, we have added Multifactor Authentication to your account and will be activated.')"
            :closable='false' :modal="true">
        <template #footer>
            <Button :label="t('two_fa_btn_start', 'Start')" class="p-button-label" @click='startTwoFAEnrollment'/>
        </template>
    </Dialog>

    <Dialog v-model:visible="showOptionalForce2fa"
            :header="t('two_fa_skip_title', 'As an added layer of security, we have added Multifactor Authentication to your account.')"
            :closable='false' :modal="true">
        <div class="confirmation-content">
            <span>{{ t('two_fa_skip_message', 'Would you like to activate it now?') }}</span>
        </div>
        <template #footer>
            <Button :label="t('two_fa_btn_later', 'I’ll do this later')"
                    class="p-button p-component p-button-outlined p-button-info" @click='skipTwoFAEnrollment'/>
            <Button :label="t('two_fa_btn_activate', 'Activate Now')" class="p-button-label"
                    @click='startTwoFAEnrollment'/>
        </template>
    </Dialog>

  <Dialog v-model:visible="showDialogBox"
          @update:visible="hideEmailAuthenticationDialogBox"
          :header="dialogHeader"
          :closable='true' :modal="true">

      <OTPBlackBox
          v-if='showDialogBox'
          @submitVerificationCodeEventCallbackEmail='submitVerificationCodeEventCallback'
          @submitVerificationCodeEventCallbackSms='submitVerificationCodeEventCallback'
          :showEmailOtp='showEmailAuthenticationDialogBox'
          :showSmsOtp='showSmsAuthenticationDialogBox'
          :emailOtpProps='emailOtpProps'
          :smsOtpProps='smsOtpProps'
      ></OTPBlackBox>

  </Dialog>

</template>

<script>
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import UserAuthenticationService from '../../service/UserAuthenticationService';
import LanguageService from '@/service/LanguageService';
import TwoFA from '@/components/two-factor-auth/TwoFA';
import t from '@/service/Translate';
import { useAutoTimer } from "@/stores/auto-timer";
import { useUserStore } from "@/stores/user-store";
import { useAutoLogout } from "@/stores/auto-logout";
import EventBus from "@/event-bus";
import { Security } from '@/service/Constants';
import LoginImage from '@/components/login/LoginImage.vue';
import OTPBlackBox from '@/components/otp/OTPBlackBox.vue';

export default {
    components: { OTPBlackBox, TwoFA, LoginImage},
    emits: ['change-language-click'],

    setup() {
        const storeAutoLogout = useAutoLogout();
        const isIdleWarningShowed = computed(() => storeAutoLogout.showIdleWarningInLogin);
        const authenticationType2FA = 1;
        const authenticationTypeEmail = 2;

        onMounted(() => {
            // add a listenener when the page is refresh to reset some values
            window.addEventListener('beforeunload', () => {
                storeAutoLogout.showIdleWarningInLogin = false;
            })

            storeAutoLogout.removeLSInAutoLogout();
        })

        onBeforeUnmount(() => {
            storeAutoLogout.removeLSInAutoLogout();
            storeAutoLogout.showIdleWarningInLogin = false;
        });

        return {
            t,
            isIdleWarningShowed,
            authenticationType2FA,
            authenticationTypeEmail,
            /**
             * Methods
             */
        }
    },

    data() {
        return {
            api: 'api/v1/login',
            apiUserRealm: 'api/v1/user-realm',
            apiSkip2fa: 'api/v1/user/mfa/skip-two-factor-authentication',
            loggingIn: false,
            loggedIn: false,
            userRealm: {
                force_2fa: false,
                secret_2fa: false,
                secret_2fa_skipped_at: false,
                default_security: "",

            },
            userService: null,
            form: {
                username: '',
                password: '',
                grant_type: 'password',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                scope: '',
                source : 'nimbus'
            },
            showAccountLocked: false,
            showConfirmForce2fa: false,
            showOptionalForce2fa: false,
            /** Email OTP variables */
            showEmailAuthenticationDialogBox: false,
            showSmsAuthenticationDialogBox: false,
            showDialogBox: false,
            dialogHeader: 'Email Authentication',
            heading: t('email_authentication_title', 'Email Authentication'),
            emailOtpProps: {
                heading: 'Email Authentication',
                subheading: 'Enter one time passcode we sent you to log in to your account.',
                validateTokenApi: '/user/authentication/email/verify',
                requestTokenApi: '/user/authentication/email/send',
                resendTimer: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER,
                resendTimerInMinutes: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER_IN_MINUTES,
            },
            smsOtpProps: {
                heading: 'SMS Authentication',
                subheading: 'Enter one time passcode we sent you to log in to your account.',
                validateTokenApi: '/user/authentication/mobile/verify',
                requestTokenApi: '/user/authentication/mobile/send',
                resendTimer: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER,
                resendTimerInMinutes: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER_IN_MINUTES,
            },
            /** Email OTP variables end */
        };
    },

    mounted() {
        this.languageService = new LanguageService();
        this.userService = new UserAuthenticationService();

        this.axios
            .get("https://api.ipify.org?format=json")
            .then(response => {
                this.form.ip = response.data.ip;
            })
    },

    computed: {
        isSel() {
            const host = window.location.host;
            const parts = host.split('.');
            return parts[0] === 'sel-end-user';
        },
    },

    methods: {
        async authenticate() {
            this.loggingIn = true;
            this.showAccountLocked = false;

            await this.axios
                .post(this.api, this.form)
                .then((res) => {
                    let store = useAutoTimer();

                    // reset the persisted data in auto-timer
                    store.resetStateValues();

                    this.getUserRealm(res.data.access_token)

                    // set the session in local storage
                    this.userService.setBearerSession(res.data)

                })
                .catch((error) => {
                    this.loggingIn = false;
                    const statusCode = error.response.status;

                    if (statusCode == 401) {
                        this.showAccountLocked = true;
                    } else if (statusCode == 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('msg_error_message', 'Error Message'),
                            detail: error.response.data.error,
                            life: 5000,
                        });
                    } 
                    else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('msg_error_message', 'Error Message'),
                            detail: this.$t('msg_login_invalid', 'Invalid Username password'),
                            life: 3000,
                        });
                    }
                });
        },
        async getUserRealm(token) {
            await this.axios.get(this.apiUserRealm, {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(async (response) => {
                  let store = useAutoTimer();
                  let userStore = useUserStore();
                  this.userRealm = response.data.data

                  this.userService.setBearer(token);
                  // get the timezone for user
                  userStore.getUserTimezone();

                  if (this.userRealm.secret_2fa) {
                      this.loggingIn = false;
                  }

                  if (this.userRealm.default_security == Security.EMAIL) {
                    /**
                     * Call API - Request for Code
                     */
                    this.dialogHeader = this.emailOtpProps.heading
                    this.showDialogBox = true;
                    this.showEmailAuthenticationDialogBox = true;
                    return;
                  }

                  if (this.userRealm.default_security == Security.OTP) {
                      /**
                       * Call API - Request for Code
                       */
                      this.dialogHeader = this.smsOtpProps.heading
                      this.showDialogBox = true;
                      this.showSmsAuthenticationDialogBox = true;
                      return;
                  }


                  // get account settings
                  await userStore.getUserAccountSettings();

                    if (this.userRealm.secret_2fa) {
                      store.getTimeEntryInCache();
                      this.loggedIn = true;
                    } else if (this.userRealm.force_2fa) {
                      this.showConfirmForce2fa = true;
                    } else if (!this.userRealm.secret_2fa_skipped_at) {
                      this.showOptionalForce2fa = true
                    } else {
                      this.twoFAStatus(true)
                    }
                })
                .catch((error) => {
                    this.$toast.add(error);
                });
        },
        async startTwoFAEnrollment() {
            this.showOptionalForce2fa = false;
            this.showConfirmForce2fa = false;
            this.loggedIn = true;
        },
        async skipTwoFAEnrollment() {
            await this.axios
                .post(this.apiSkip2fa, [], this.userService.getBearer())
                .then(() => {
                    this.twoFAStatus(true)
                });
        },
        async twoFAStatus(result) {
            this.loggingIn = true
            if (result) {
                let store = useAutoTimer();
                let redirectUrl = this.$route.query.redirect ? this.$route.query.redirect : '/';
                this.userService.setTwoFactorAuthentication(Date.now())

                this.userService.removeVisitedUrl(); // remove visited url

                // make sure to resolve permission request first before going to another page
                this.userService.cachePermission().then(() => {
                    store.getTimeEntryInCache();
                    this.$router.push(redirectUrl);
                });
            } else {
                await this.userService.setBearer(null);
                await this.userService.setTwoFactorAuthentication(null);
                this.$router.push('/login');
                location.reload();
            }
        },

        forgotPassword() {
            this.$router.push("/forgot-password");
        },
        /**  Email Otp Method */
        hideEmailAuthenticationDialogBox(value) {
          if (value == false) {
            this.showEmailAuthenticationDialogBox = false;
            this.showSmsAuthenticationDialogBox = false;
            this.showDialogBox = false;
          }
        },
        submitVerificationCodeEventCallback(event, isSuccess, data) {
          this.twoFAStatus(isSuccess);
          return;
        },
        /** Email Otp End */
    },
};
</script>
